import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  LinearProgress,
} from "@mui/material";

export const RechargeWalletDialog = ({
  rechargeWalletDialogOpen,
  rechargeWalletIcon,
}) => {
  return (
    <Dialog open={rechargeWalletDialogOpen}>
      <DialogTitle>
        <center>
          <img src={rechargeWalletIcon} width={"100px"} />
        </center>
        <br />
        <strong>Recharging negative wallet balance...</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your wallet balance is negative. Recharging your wallet balance to
          resume dialling...
        </DialogContentText>
        <div style={{ height: "1.5em" }} />
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};
