import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const LiveTransferGuideDialog = ({
  liveTransferGuideDialogOpen,
  handleCloseLiveTransferGuideDialog,
  questionMarkIcon,
}) => {
  return (
    <Dialog
      open={liveTransferGuideDialogOpen}
      onClose={handleCloseLiveTransferGuideDialog}
    >
      <DialogTitle>
        <center>
          <img src={questionMarkIcon} width={"100px"} />
        </center>
        <br />
        <strong>
          Live Transfer did not ring your phone and calling queue is off?
        </strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ol>
            <li>
              Wait 1-2 minutes to check if the calling queue does not turn back
              on automatically.
            </li>
            <li>
              If you see a new live transfer on the right-hand side of the home
              page but for some reason your phone didn't ring, call the lead
              back and attempt the sale.
            </li>
            <li>
              In case the lead does not pick up, or they express no interest,
              resume the "Calling Queue" once again to proceed dialling again.
            </li>
          </ol>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseLiveTransferGuideDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
