import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Button,
} from "@mui/material";
import { auth, db, functions } from "../config/firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import "../styles/test-phone-calls.css"; // import the CSS file
import "../styles/home.css";

import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import { queueMap } from "../utils/queue-map";

import noLiveTransfersIcon from "../assets/images/empty-box.png";
import wavingHandIcon from "../assets/images/waving-hand.png";
import questionMarkIcon from "../assets/images/question-mark.png";
import failedWalletIcon from "../assets/images/wallet.png";
import rechargeWalletIcon from "../assets/images/wallet-recharge.png";
import { isHolidayToday } from "../utils/is-holiday-today";
import { HomePageCard } from "./home/home-page-card";
import { ResumingCallsForQueueDialog } from "./home/resuming-calls-for-queue-dialog";
import { PausingCallsForQueueDialog } from "./home/pausing-calls-for-queue-dialog";
import { RechargeWalletDialog } from "./home/recharge-wallet-dialog";
import { FailedRechargeDialog } from "./home/failed-recharge-dialog";
import { CannotCallOnHolidayDialog } from "./home/cannot-call-on-holiday-dialog";
import { LatestLiveTransfers } from "./home/latest-live-transfers";
import { AgencyAdminButtons } from "./home/agency-admin-buttons";
import { Link } from "react-router-dom";

function Home() {
  const [latestLiveTransfers, setLatestLiveTransfers] = useState([]);
  const [leadsPhoneNumbersForCallType, setLeadsPhoneNumbersForCallType] =
    useState([]);
  const [needsToBuyMoreLeads, setNeedsToBuyMoreLeads] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    totalLeads: 0,
    totalLiveTransfers: 0,
    totalPickUps: 0,
    totalPickUpsFromEvenDials: 0,
    totalDials: 0,
    totalFailedNumbers: 0,
    totalVoiceMails: 0,
    totalBusyAndNoAnswer: 0,
    totalLeadCallBacks: 0,
    totalLocalLeadsLiveTransfers: 0,
    liveTransferToPickupRate: 0,
    totalOverallDials: 0,
    totalConnectedCalls: 0,
    totalPickUpsLocal: 0,
    totalNoAnswer: 0,
    walletBalance: 0,
    freeMinutes: 0,
    queueActive: false,
    leadsDialedMoreThanOnce: 0,
    callingQueuePaused: false,
  });
  const [firstName, setFirstName] = useState("");
  const [agentData, setAgentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingLiveTransfers, setLoadingLiveTransfers] = useState(true);
  const [averageDialsToPickUp, setAverageDialsToPickUp] = useState(0);

  /**
   * Create loading state for each card displayed in the dashboard
   */
  const [loadingTotalDials, setLoadingTotalDials] = useState(true);
  const [loadingTotalLeads, setLoadingTotalLeads] = useState(true);
  const [loadingTotalPickUps, setLoadingTotalPickUps] = useState(true);
  const [loadingTotalVoiceMails, setLoadingTotalVoiceMails] = useState(true);
  const [loadingTotalLeadCallBacks, setLoadingTotalLeadCallBacks] =
    useState(true);
  const [loadingFreeMinutes, setLoadingFreeMinutes] = useState(true);
  const [loadingTotalLiveTransfers, setLoadingTotalLiveTransfers] =
    useState(true);

  // const [resumingCallsDialogOpen, setResumingCallsDialogOpen] = useState(false);
  const [resumingCallsForQueueDialogOpen, setResumingCallsForQueueDialogOpen] =
    useState(false);
  const [pausingCallsForQueueDialogOpen, setPausingCallsForQueueDialogOpen] =
    useState(false);
  const [rechargeWalletDialogOpen, setRechargeWalletDialogOpen] =
    useState(false);
  const [failedRechargeDialogOpen, setFailedRechargeDialogOpen] =
    useState(false);
  const [cannotCallOnHolidyDialogOpen, setCannotCallOnHolidayDialogOpen] =
    useState(false);
  // const [pausingCallsDialogOpen, setPausingCallsDialogOpen] = useState(false);
  const [queueName, setQueueName] = useState("");

  const [selectedQueue, setSelectedQueue] = useState("");

  const [agencyAdmin, setAgencyAdmin] = useState(false);

  const [agentSelectionDashboardOpen, setAgentSelectionDashboardOpen] =
    useState(false);

  const [liveTransferGuideDialogOpen, setGuideDialogOpen] = useState(false);

  const [agents, setAgents] = useState([]);

  const handleOpenGuideDialog = () => {
    setGuideDialogOpen(true);
  };

  const handleCloseLiveTransferGuideDialog = () => {
    setGuideDialogOpen(false);
  };

  const handleClickOpenAgentSelectionDashboard = () => {
    setAgentSelectionDashboardOpen(true);
  };

  const handleCloseAgentSelectionDashboard = () => {
    setAgentSelectionDashboardOpen(false);
  };

  const handleOpenRechargingWalletModal = () => {
    setRechargeWalletDialogOpen(true);
  };

  const handleCloseRechargingWalletModal = () => {
    setRechargeWalletDialogOpen(false);
  };

  const handleSelectAgent = (agent) => {
    // if (process.env.NODE_ENV !== "production") {
    localStorage.setItem("agentId", agent.id);
    localStorage.setItem("agentUid", agent.uid);
    handleCloseAgentSelectionDashboard();
    window.location.reload();
    // }
  };

  useEffect(() => {
    const fetchAgents = async () => {
      const agentsRef = collection(db, "agents");
      const agentsSnapshot = await getDocs(agentsRef);
      const agentsData = agentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgents(agentsData);
    };

    fetchAgents();
  }, []);

  const handleQueueChange = async (event) => {
    setLoadingLiveTransfers(true);
    setLoadingFreeMinutes(true);
    setLoadingTotalVoiceMails(true);
    setLoadingTotalLeadCallBacks(true);
    setLoadingTotalDials(true);
    setLoadingTotalLeads(true);
    setLoadingTotalPickUps(true);
    setLoadingTotalLiveTransfers(true);
    setLatestLiveTransfers([]);
    setSelectedQueue(event.target.value);

    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) return;

    const agentDoc = agentSnapshot.docs[0];

    await updateDoc(agentDoc.ref, {
      last_selected_queue: event.target.value,
    });
  };

  useEffect(() => {
    const initializeDashboardData = () => ({
      totalLeads: 0,
      totalLiveTransfers: 0,
      totalPickUps: 0,
      totalPickUpsFromEvenDials: 0,
      totalLiveTransfersFromEvenDials: 0,
      totalTranscribedCalls: 0,
      totalDials: 0,
      totalFailedNumbers: 0,
      totalVoiceMails: 0,
      totalLeadCallBacks: 0,
      totalBusyAndNoAnswer: 0,
      totalLocalLeadsLiveTransfers: 0,
      liveTransferToPickupRate: 0,
      totalOverallDials: 0,
      totalConnectedCalls: 0,
      totalPickUpsLocal: 0,
      totalNoAnswer: 0,
      walletBalance: 0,
      freeMinutes: 0,
      queueActive: false,
      leadsDialedMoreThanOnce: 0,
      callingQueuePaused: false,
    });

    setDashboardData(initializeDashboardData());
    setLeadsPhoneNumbersForCallType([]);

    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const _agentQuery = query(agentRef, where("uid", "==", user.uid));
      const _agentSnapshot = await getDocs(_agentQuery);
      const _agentDoc = _agentSnapshot.docs[0];
      setAgencyAdmin(_agentDoc.data().agency_admin ?? false);

      let agentUid = localStorage.getItem("agentUid");
      if (!agentUid) {
        agentUid = user.uid;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentUid", agentUid);
      }

      const agentQuery = query(agentRef, where("uid", "==", agentUid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];

      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      // Fetch and set the first name
      const agentData = agentDoc.data();
      setFirstName(agentData.first_name);

      if (!selectedQueue) {
        setSelectedQueue(agentData.last_selected_queue ?? "med_supp_cc_lt");
      }

      // Function to check if all queues are paused
      const areAllQueuesPaused = (queueStatus) => {
        return Object.values(queueStatus).every((queue) => queue.paused);
      };

      // Listen for changes in the agent's document
      const agentDocRef = doc(db, "agents", agentId);
      const unsubscribeAgent = onSnapshot(agentDocRef, (doc) => {
        const data = doc.data();
        const allQueuesPaused = areAllQueuesPaused(data.queue_status);
        setDashboardData((prevState) => ({
          ...prevState,
          callingQueuePaused: allQueuesPaused,
          freeMinutes: data.free_minutes,
          dialing_speed: data.dialing_speed ?? "medium",
          walletBalance: data.wallet_balance,
        }));
        setAgentData({ id: doc.id, ...data });
        setLoading(false);
        setLoadingFreeMinutes(false);
      });

      // Listen for changes in the voicemail_trigger subcollection
      const voicemailTriggerRef = collection(
        db,
        "agents",
        agentId,
        "voicemail_trigger"
      );
      const unsubscribeVoicemailTrigger = onSnapshot(
        voicemailTriggerRef,
        (snapshot) => {
          const totalVoicemail = snapshot.docs.filter(
            (doc) => doc.data().call_type === selectedQueue
          ).length;
          setDashboardData((prevState) => ({
            ...prevState,
            totalVoiceMails: totalVoicemail,
          }));
          setLoadingTotalVoiceMails(false);
        }
      );

      // Listen for changes in the lead_call_backs subcollection
      const leadCallBacksRef = collection(
        db,
        "agents",
        agentId,
        "lead_call_backs"
      );
      const unsubscribeLeadCallBacks = onSnapshot(
        leadCallBacksRef,
        async (snapshot) => {
          const leadDocPromises = snapshot.docs.map((_doc) => {
            const leadId = _doc.data()["lead_id"];
            const leadDocRef = doc(db, "agents", agentId, "leads", leadId);
            return getDoc(leadDocRef);
          });

          const leadDocs = await Promise.all(leadDocPromises);
          const totalLeadCallBacks = leadDocs.filter(
            (leadDoc) =>
              leadDoc.exists() && leadDoc.data()["lead_type"] === selectedQueue
          ).length;

          setDashboardData((prevState) => ({
            ...prevState,
            totalLeadCallBacks,
          }));

          setLoadingTotalLeadCallBacks(false);
        }
      );

      // Listen for changes in the leads subcollection
      const leadsRef = collection(db, "agents", agentId, "leads");
      const filteredLeadsQuery = query(
        leadsRef,
        where("lead_type", "==", selectedQueue)
      );

      const unsubscribeLeads = onSnapshot(
        filteredLeadsQuery,
        async (snapshot) => {
          /**
           * filter leads that do not have archived == true
           */
          const totalLeadsFiltered = snapshot.docs.filter(
            (doc) => !doc.data().archived
          );
          const totalLeads = totalLeadsFiltered.length;
          let totalPickUps = 0;
          let totalPickUpsFromEvenDials = 0;
          let totalPickUpsLocal = 0;
          let totalFailed = 0;
          let totalLeadsDialed = 0;
          let totalNoAnswer = 0;
          let totalDialsFiltered = 0;
          let leadsDialedMoreThanOnce = 0;
          const localLeadsStates = [];
          const _leadsPhoneNumbers = [];

          for (const doc of snapshot.docs) {
            const data = doc.data();
            const {
              full_state,
              call_status,
              call_attempts,
              failed_number,
              phone_number,
            } = data;

            _leadsPhoneNumbers.push(phone_number);

            if (
              ["Florida", "Indiana", "Michigan", "Ohio", "Texas"].includes(
                full_state
              )
            ) {
              localLeadsStates.push(phone_number);
            }

            if (call_status === "picked_up") {
              totalPickUps++;
            }

            if (failed_number) {
              // totalFailed++;
            } else {
              totalDialsFiltered += call_attempts || 0;
              if (call_status !== "queued") {
                totalLeadsDialed++;
              }
            }

            if (["no_answer", "busy"].includes(call_status)) {
              totalNoAnswer++;
            }
          }
          // setLeadsPhoneNumbersForCallType(_leadsPhoneNumbers);

          // Listen for changes in the live_transfers subcollection
          const transcribedCallsRef = collection(
            db,
            "agents",
            agentId,
            "transcribed_calls"
          );
          const _transcribedCallsQuery = query(transcribedCallsRef);
          let _totalConnectedCalls = 0;
          let transcribedCalls = await getDocs(_transcribedCallsQuery);

          transcribedCalls.docs.map((doc) => {
            if (_leadsPhoneNumbers.includes(doc.data().lead_phone_number)) {
              _totalConnectedCalls++;
            }
          });

          setDashboardData((prevState) => ({
            ...prevState,
            totalLeads,
            totalConnectedCalls: _totalConnectedCalls,
            totalPickUps,
            totalPickUpsFromEvenDials,
            totalDials: totalLeadsDialed,
            totalOverallDials: totalDialsFiltered,
            leadsDialedMoreThanOnce,
            totalFailedNumbers: totalFailed,
            totalNoAnswer,
            totalPickUpsLocal,
          }));

          setLoadingTotalDials(false);
          setLoadingTotalLeads(false);
          setLoadingTotalPickUps(false);
        }
      );

      // Listen for changes in the live_transfers subcollection
      const liveTransfersRef = collection(
        db,
        "agents",
        agentId,
        "live_transfers"
      );
      const filteredLiveTransfersQuery = query(
        liveTransfersRef,
        where("call_type", "==", selectedQueue),
        orderBy("time_stamp", "desc")
      );

      const unsubscribeLiveTransfers = onSnapshot(
        filteredLiveTransfersQuery,
        async (snapshot) => {
          if (snapshot.empty) {
            setLoadingLiveTransfers(false);
            setLoadingTotalLiveTransfers(false);
            return;
          }
          const totalLiveTransfers = snapshot.size;

          /**
           * Get the latest 3 live transfers and set the state
           * to display them in the UI
           */
          let liveTransferDocs = snapshot.docs;
          let liveTransfers = [];

          for (let i = 0; i < liveTransferDocs.length; i++) {
            if (liveTransfers.length >= 3) break;
            console.dir(liveTransferDocs[i].data());
            const doc = liveTransferDocs[i];
            const leadPhoneNumber = doc.data()["lead_phone_number"];
            const timeStamp = doc.data()["time_stamp"];
            const leadDocRef = query(
              collection(db, "agents", agentId, "leads"),
              where("phone_number", "==", leadPhoneNumber),
              where("lead_type", "==", selectedQueue),
              limit(1)
            );
            const leadDocs = await getDocs(leadDocRef);

            if (!leadDocs.empty) {
              const leadDoc = leadDocs.docs[0];
              if (!leadDoc) continue;
              liveTransfers.push({
                full_name: `${leadDoc.data()["first_name"]} ${
                  leadDoc.data()["last_name"]
                }`,
                lead_phone_number: leadPhoneNumber,
                state: leadDoc.data()["full_state"],
                call_type: leadDoc.data()["lead_type"],
                time_stamp: moment(timeStamp.toDate())
                  .tz(agentData.time_zone)
                  .format("MM/DD/YYYY hh:mm A"),
              });
            }
          }

          setLoadingTotalLiveTransfers(false);

          setLatestLiveTransfers(liveTransfers);

          setDashboardData((prevState) => ({
            ...prevState,
            totalLiveTransfers,
          }));

          setLoadingLiveTransfers(false);
        }
      );

      // Cleanup function to unsubscribe from snapshots
      return () => {
        unsubscribeAgent();
        unsubscribeLeadCallBacks();
        unsubscribeLeads();
        unsubscribeLiveTransfers();
        unsubscribeVoicemailTrigger();
      };
    };

    fetchData();
  }, [selectedQueue]);

  // const showResumingModal = () => {
  //   setResumingCallsDialogOpen(true);
  // };

  const showResumingQueueModal = (currentQueueName) => {
    setQueueName(currentQueueName);
    setResumingCallsForQueueDialogOpen(true);
  };

  // const showPausingModal = () => {
  //   setPausingCallsDialogOpen(true);
  // };

  const showPausingQueueModal = (currentQueueName) => {
    setQueueName(currentQueueName);
    setPausingCallsForQueueDialogOpen(true);
  };

  // const hideResumingModal = () => {
  //   setResumingCallsDialogOpen(false);
  // };

  const hideResumingQueueModal = () => {
    setQueueName("");
    setResumingCallsForQueueDialogOpen(false);
  };

  // const hidePausingModal = () => {
  //   setPausingCallsDialogOpen(false);
  // };

  const hidePausingQueueModal = () => {
    setQueueName("");
    setPausingCallsForQueueDialogOpen(false);
  };

  const showRechargingWalletModal = () => {
    setRechargeWalletDialogOpen(true);
  };

  const hideRechargingWalletModal = () => {
    setRechargeWalletDialogOpen(false);
  };

  const showFailedRechargeModal = () => {
    setFailedRechargeDialogOpen(true);
  };

  const hideFailedRechargeModal = () => {
    setFailedRechargeDialogOpen(false);
  };

  const showCannotCallOnHolidayModal = () => {
    setCannotCallOnHolidayDialogOpen(true);
  };

  const hideCannotCallOnHolidayModal = () => {
    setCannotCallOnHolidayDialogOpen(false);
  };

  const resumeCallsForAllQueues = async () => {
    // Code to call the cloud function
    const resumeCallsForAllQueues = httpsCallable(
      functions,
      "resumeCallsForAllQueues"
    );

    return await resumeCallsForAllQueues().then((result) => {
      if (result.data == null) {
        return;
      } else {
        return;
      }
    });
  };

  const resumeCallsForQueue = async (queue) => {
    // Code to call the cloud function
    const resumeCallsForQueue = httpsCallable(functions, "resumeCallsForQueue");

    return await resumeCallsForQueue({
      queue: queue,
    }).then((result) => {
      if (result.data == null) {
        return;
      } else {
        return;
      }
    });
  };

  const pauseCallsForQueueCF = async (queue) => {
    // Code to call the cloud function
    const pauseCallsForQueue = httpsCallable(functions, "pauseCallsForQueue");

    return await pauseCallsForQueue({
      queue: queue,
    }).then((result) => {
      if (result.data == null) {
        return;
      } else {
        return;
      }
    });
  };

  const pauseCallsForQueue = async (queue) => {
    await pauseCallsForQueueCF(queue);
  };

  const handlePauseResumeQueue = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) return;

    const agentDoc = agentSnapshot.docs[0];
  };

  const checkIfAgentNeedsMoreLeads = async (queue) => {
    // Code to call the cloud function
    const _checkIfAgentNeedsMoreLeads = httpsCallable(
      functions,
      "checkIfAgentNeedsMoreLeads"
    );

    return await _checkIfAgentNeedsMoreLeads({
      queue: queue,
    }).then((result) => {
      if (result.data == null) {
        return;
      } else {
        return;
      }
    });
  };

  const getActiveQueues = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);
    const agentDoc = agentSnapshot.docs[0];

    if (!agentDoc.exists()) {
      console.error("No such document!");
      return [];
    }

    const queueStatus = agentDoc.data().queue_status;
    const activeQueues = Object.keys(queueStatus).filter(
      (queue) => !queueStatus[queue].paused
    );

    return activeQueues;
  };

  const handleToggle = async (queue) => {
    if (!agentData) return;

    const newStatus = !agentData.queue_status[queue].paused;
    const updatedQueueStatus = {
      ...agentData.queue_status,
      [queue]: { paused: newStatus },
    };
    const agentDocRef = doc(db, "agents", agentData.id);

    if (newStatus == true) {
      showPausingQueueModal(queue);

      await updateDoc(agentDocRef, { queue_status: updatedQueueStatus });
      await pauseCallsForQueue(queue);

      hidePausingQueueModal();
    } else {
      /**
       * Run preliminary checks on the agent's wallet_balance and trigger
       * the rechargeAgentWallet cloud function before initiating calls if necessary.
       */
      const walletBalance = agentData.wallet_balance;
      const agentRechargeAmount = agentData.recharge_amount;

      const _isHolidayToday = isHolidayToday();

      if (!_isHolidayToday) {
        if (walletBalance < 0) {
          const rechargeAgentWallet = httpsCallable(
            functions,
            "rechargeAgentWallet"
          );

          showRechargingWalletModal();

          await rechargeAgentWallet({
            recharge_amount: Math.abs(walletBalance) + agentRechargeAmount,
          })
            .then(async (result) => {
              if (result.data == true) {
                console.log("Agent wallet recharged successfully");

                hideRechargingWalletModal();

                showResumingQueueModal(queue);

                await updateDoc(agentDocRef, {
                  queue_status: updatedQueueStatus,
                });
                await resumeCallsForQueue(queue);

                hideResumingQueueModal();

                setAgentData((prevData) => ({
                  ...prevData,
                  queue_status: updatedQueueStatus,
                }));
              } else {
                console.log("Agent wallet recharge failed");
                hideRechargingWalletModal();
                showFailedRechargeModal();
              }
            })
            .catch((error) => {
              console.error("Error recharging agent wallet:", error);
              hideRechargingWalletModal();
              showFailedRechargeModal();
            });
        } else {
          showResumingQueueModal(queue);

          await updateDoc(agentDocRef, {
            queue_status: updatedQueueStatus,
          });
          await resumeCallsForQueue(queue);

          hideResumingQueueModal();
        }
      } else {
        showCannotCallOnHolidayModal();
      }
    }
  };

  const queues = Object.keys(queueMap);

  const numberWithCommas = (x) => {
    if (x >= 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  };

  const handleDialingSpeedChange = async (event) => {
    const value = event.target.value;
    setDashboardData({
      ...dashboardData,
      dialing_speed: value,
    });

    const user = auth.currentUser;
    if (!user) return;

    const agentRef = collection(db, "agents");
    const agentQuery = query(agentRef, where("uid", "==", user.uid));
    const agentSnapshot = await getDocs(agentQuery);

    if (agentSnapshot.empty) return;

    const agentDoc = agentSnapshot.docs[0];
    const agentDocRef = doc(db, "agents", agentDoc.id);
    await updateDoc(agentDocRef, {
      dialing_speed: value,
    });
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div
      className="wrapper"
      style={{
        display: "grid",
        gridTemplateColumns: "2.5fr 1fr",
        gap: "4rem",
        padding: "1rem 0rem",
      }}
    >
      <div>
        <center>
          {agencyAdmin === true ? (
            <AgencyAdminButtons
              agentData={agentData}
              handleSelectAgent={handleSelectAgent}
              handleClickOpenAgentSelectionDashboard={
                handleClickOpenAgentSelectionDashboard
              }
              agentSelectionDashboardOpen={agentSelectionDashboardOpen}
              handleCloseAgentSelectionDashboard={
                handleCloseAgentSelectionDashboard
              }
              agents={agents}
            />
          ) : (
            <></>
          )}
          <img src={wavingHandIcon} width={"50px"} />
          <br />
          <Typography variant="h5" component="h1" gutterBottom>
            <strong>Welcome back, {firstName}</strong>
          </Typography>
          <div>
            <label htmlFor="queueSelect">Select Queue: </label>
            <select
              id="queueSelect"
              value={selectedQueue}
              onChange={async (e) => await handleQueueChange(e)}
              style={{
                fontSize: "14px",
                padding: "8px",
              }}
            >
              <option value="">--Select a Queue--</option>
              {queues.map((queue) => (
                <option key={queue} value={queue}>
                  {queueMap[queue]}
                </option>
              ))}
            </select>
          </div>
        </center>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "2rem", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={6} md={3}>
            {needsToBuyMoreLeads ? (
              <Card
                sx={{
                  height: "100%",
                  backgroundColor: "#5f6cfa",
                  color: "#fff"
                }}
              >
                <CardContent>
                  <Typography variant="h7">
                    <b>Total leads imported</b>
                  </Typography>
                  {!loadingTotalLeads ? (
                    <>
                      <Typography variant="h5">
                        {numberWithCommas(dashboardData.totalLeads)}
                      </Typography>
                      <br />
                      <Link
                        to={
                          "https://get.free-benefits.info/widget/form/qn1q9aCYcJ180KEacwbQ"
                        }
                      >
                        <Button variant="contained" color="secondary">Buy more leads</Button>
                      </Link>
                    </>
                  ) : (
                    <>
                      <br />
                      <br />
                      <LinearProgress />
                    </>
                  )}
                </CardContent>
              </Card>
            ) : (
              <HomePageCard
                title={"Total leads imported"}
                content={numberWithCommas(dashboardData.totalLeads)}
                loading={!loadingTotalLeads}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HomePageCard
              title={"Total dials"}
              content={numberWithCommas(dashboardData.totalOverallDials)}
              loading={!loadingTotalDials}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HomePageCard
              title={"Total Answered Calls"}
              content={numberWithCommas(dashboardData.totalPickUps)}
              loading={!loadingTotalPickUps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HomePageCard
              title={"Total Voicemails"}
              content={numberWithCommas(
                dashboardData.totalConnectedCalls - dashboardData.totalPickUps
              )}
              loading={!loadingTotalVoiceMails && !loadingTotalPickUps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HomePageCard
              title={"Total live transfers"}
              content={numberWithCommas(dashboardData.totalLiveTransfers)}
              loading={!loadingTotalLiveTransfers}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <HomePageCard
              title={"Total scheduled call backs"}
              content={dashboardData.totalLeadCallBacks}
              loading={!loadingTotalLeadCallBacks}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h7" gutterBottom>
                  <b>Dialing speed</b>
                </Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Dialing speed</InputLabel>
                  <Select
                    value={dashboardData.dialing_speed}
                    onChange={handleDialingSpeedChange}
                    label="Dialing speed"
                    sx={{ fontSize: "14px" }}
                  >
                    <MenuItem value={"very_slow"}>Very Slow</MenuItem>
                    <MenuItem value={"slow"}>Slow</MenuItem>
                    <MenuItem value={"medium"}>Medium</MenuItem>
                    <MenuItem value={"fast"}>Fast</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Box mt={7}>
            <center>
              <Grid item xs={12}>
                <Typography variant="h5" component="h1" gutterBottom>
                  <strong>Calling Queues</strong>
                </Typography>

                {queues.map((queue) => (
                  <FormControlLabel
                    key={queue}
                    style={{
                      padding: "0.75em",
                      margin: "10px",
                      fontSize: "12px",
                      borderRadius: "10px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                    control={
                      <Switch
                        checked={!agentData.queue_status[queue].paused}
                        onChange={() => handleToggle(queue)}
                      />
                    }
                    label={queueMap[queue]}
                  />
                ))}
              </Grid>
            </center>
          </Box>
        </Grid>
        <ResumingCallsForQueueDialog
          resumingCallsForQueueDialogOpen={resumingCallsForQueueDialogOpen}
          queueName={queueName}
        />
        <PausingCallsForQueueDialog
          pausingCallsForQueueDialogOpen={pausingCallsForQueueDialogOpen}
          queueName={queueName}
        />
        <RechargeWalletDialog
          rechargeWalletDialogOpen={rechargeWalletDialogOpen}
          rechargeWalletIcon={rechargeWalletIcon}
        />
        <FailedRechargeDialog
          failedRechargeDialogOpen={failedRechargeDialogOpen}
          failedWalletIcon={failedWalletIcon}
          hideFailedRechargeModal={hideFailedRechargeModal}
        />
        <CannotCallOnHolidayDialog
          cannotCallOnHolidyDialogOpen={cannotCallOnHolidyDialogOpen}
          hideCannotCallOnHolidayModal={hideCannotCallOnHolidayModal}
        />
      </div>
      <LatestLiveTransfers
        handleOpenLiveTransferGuideDialog={handleOpenGuideDialog}
        liveTransferGuideDialogOpen={liveTransferGuideDialogOpen}
        handleCloseLiveTransferGuideDialog={handleCloseLiveTransferGuideDialog}
        questionMarkIcon={questionMarkIcon}
        latestLiveTransfers={latestLiveTransfers}
        loadingLiveTransfers={loadingLiveTransfers}
        queueMap={queueMap}
        noLiveTransfersIcon={noLiveTransfersIcon}
      />
    </div>
  );
}

export default Home;
