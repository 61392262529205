import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

export const AgencyAdminButtons = ({
  agentData,
  handleClickOpenAgentSelectionDashboard,
  agentSelectionDashboardOpen,
  handleCloseAgentSelectionDashboard,
  handleSelectAgent,
  agents,
}) => {
  return (
    <>
      <Typography variant="h5" component="h1" gutterBottom>
        Current agent: {agentData?.email}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpenAgentSelectionDashboard}
      >
        Show Agents
      </Button>
      <Dialog
        open={agentSelectionDashboardOpen}
        onClose={handleCloseAgentSelectionDashboard}
      >
        <DialogTitle>Agent Accounts</DialogTitle>
        <DialogContent>
          <List>
            {agents.map((agent, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${agent.first_name} ${agent.last_name}`}
                  secondary={agent.email}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSelectAgent(agent)}
                  >
                    Select
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <br />
      <br />
      <Link to={"/agency-admin"}>
        <Button variant="outlined" color="primary">
          Access Admin Dashboard
        </Button>
      </Link>
      <br />
      <br />
    </>
  );
};
