import { Divider, LinearProgress, Typography } from "@mui/material";
import { LiveTransferGuideDialog } from "./live-transfer-guide-dialog";
import { Link } from "react-router-dom";

export const LatestLiveTransfers = ({
  handleOpenLiveTransferGuideDialog,
  liveTransferGuideDialogOpen,
  handleCloseLiveTransferGuideDialog,
  questionMarkIcon,
  noLiveTransfersIcon,
  queueMap,
  latestLiveTransfers,
  loadingLiveTransfers,
}) => {
  return (
    <div className="latestLiveTransfers">
      <Typography variant="h5" component="h1" gutterBottom>
        <strong>Latest Live Transfers</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Here, you can view the latest 3 live transfers that have been made in
        the selected queue. Each live transfer displays the full name, lead
        phone number, state, call type, and time stamp of the live transfer. If
        you need to view more live transfers, please go to the{" "}
        <Link to={"/live-transfers"}>Live Transfers & Call Backs</Link> page
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom>
        <strong>
          Live Transfer did not ring your phone and calling queue is off?{" "}
          <Link onClick={handleOpenLiveTransferGuideDialog}>Click here</Link>
        </strong>
      </Typography>
      <LiveTransferGuideDialog
        liveTransferGuideDialogOpen={liveTransferGuideDialogOpen}
        handleCloseLiveTransferGuideDialog={handleCloseLiveTransferGuideDialog}
        questionMarkIcon={questionMarkIcon}
      />
      <Divider />
      <br />
      {loadingLiveTransfers ? (
        <LinearProgress />
      ) : latestLiveTransfers.length === 0 ? (
        <>
          <div
            style={{
              height: "4em",
            }}
          />
          <center>
            <img src={noLiveTransfersIcon} width={"100px"} />
            <br />
            <br />
            <Typography>
              No live transfers to display yet, start dialling by turning on a
              Calling Queue!
            </Typography>
          </center>
        </>
      ) : (
        latestLiveTransfers.map((liveTransfer) => (
          <div key={liveTransfer.full_name}>
            <Typography variant="h8">
              Full name: {liveTransfer.full_name}
            </Typography>
            <br />
            <Typography variant="h8">
              Lead phone number: {liveTransfer.lead_phone_number}
            </Typography>
            <br />
            <Typography variant="h8">State: {liveTransfer.state}</Typography>
            <br />
            <Typography variant="h8">
              Call type: {queueMap[liveTransfer.call_type]}
            </Typography>
            <br />
            <Typography variant="h8">
              Time stamp: {liveTransfer.time_stamp}
            </Typography>
            <br />
            <br />
            <Divider />
            <br />
          </div>
        ))
      )}
    </div>
  );
};
