import { BlockOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const CannotCallOnHolidayDialog = ({
  cannotCallOnHolidyDialogOpen,
  hideCannotCallOnHolidayModal,
}) => {
  return (
    <Dialog open={cannotCallOnHolidyDialogOpen}>
      <DialogTitle>
        <center>
          <BlockOutlined
            sx={{
              fontSize: "72px",
            }}
          />
        </center>
        <br />
        <strong>
          You are not allowed to call during a recognized federal holiday
        </strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please try again after the holiday is over in order to keep calling
          your leads.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideCannotCallOnHolidayModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
