import { Card, CardContent, LinearProgress, Typography } from "@mui/material";

export const HomePageCard = ({ title, content, loading }) => {
  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <CardContent>
        <Typography variant="h7">
          <b>{title}</b>
        </Typography>
        {loading ? (
          <Typography variant="h5">{content}</Typography>
        ) : (
          <>
            <br />
            <br />
            <LinearProgress />
          </>
        )}
      </CardContent>
    </Card>
  );
};
