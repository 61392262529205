import {
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";

export const PausingCallsForQueueDialog = ({
  pausingCallsForQueueDialogOpen,
  queueName,
}) => {
  return (
    <Dialog open={pausingCallsForQueueDialogOpen}>
      <DialogContent>
        <DialogContentText>Pausing {queueName} calls...</DialogContentText>
        <div style={{ height: "1.5em" }} />
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};
