import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const FailedRechargeDialog = ({
  failedRechargeDialogOpen,
  failedWalletIcon,
  hideFailedRechargeModal,
}) => {
  return (
    <Dialog open={failedRechargeDialogOpen}>
      <DialogTitle>
        <center>
          <img src={failedWalletIcon} width={"100px"} />
        </center>
        <br />
        <strong>Failed to recharge your negative wallet balance</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please try again later or fill out a feedback form if the issue
          persists to get help from the support team and begin dialling again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideFailedRechargeModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
