import { TableCell, TableHead, TableRow } from "@mui/material";

export const AgentTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Free Minutes</TableCell>
        <TableCell>Software Version</TableCell>
        <TableCell>Total Leads</TableCell>
        <TableCell>Dial Speed</TableCell>
        <TableCell>Wallet Balance</TableCell>
        <TableCell>Stripe Customer ID</TableCell>
      </TableRow>
    </TableHead>
  );
};
