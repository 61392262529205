import {
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";

export const ResumingCallsForQueueDialog = ({
  resumingCallsForQueueDialogOpen,
  queueName,
}) => {
  return (
    <Dialog open={resumingCallsForQueueDialogOpen}>
      <DialogContent>
        <DialogContentText>Resuming {queueName} calls...</DialogContentText>
        <div style={{ height: "1.5em" }} />
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};
